<template>
  <div class="row">
    <div class="col-12">
      <BaseAlert
        type="primary"
        :dismissible="true"
      >
        <span class="h1 text-white">
          <i class="fas fa-info-circle"></i>
        </span>
        <strong class="ml-4 h1 text-white">{{getTrans('messages.boost_my_link')}}</strong>
        <div>{{getTrans('messages.links_info_text')}}</div>
        <a href="https://support.divatraffic.com/en/help-center/article/buying-traffic-to-onlyfans-manyvids-and-other-content-sites" >{{getTrans('messages.read_more_about_links')}}</a>
      </BaseAlert>
    </div>
    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-header">
          <div class="h2">{{profileId ? getTrans('messages.edit_link') : getTrans('messages.add_link')}}</div>
        </div>
        <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="input-suffix">
              <base-input
                :label="getTrans('messages.url')"
                v-model="url"
                name="search"
                :error="getError('url')"
              ></base-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="input-suffix">
              <base-input
                :label="getTrans('messages.title')"
                v-model="title"
                :error="getError('title')"
              ></base-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="input-suffix">
              <base-input
                :label="getTrans('messages.description')"
                v-model="message"
                :type="'textarea'"
                :error="getError('description')"
              ></base-input>
            </div>
          </div>
        </div>
        <div class="row" v-if="showModels">
          <div class="col-12 col-lg-6">
            <div class="input-suffix">
              <base-input
                :error="getError('user_id')"
                :label="getTrans('messages.model')"
              >
                <el-select
                  type="primary"
                  v-if="getModels"
                  v-model="model"
                  :disabled="editing ? true : false"
                >
                  <el-option :value="getUser.id" :label="getTrans('messages.this_account')">{{getTrans('messages.this_account')}}</el-option>
                  <el-option
                    v-for="model in getModels"
                    :key="model.id"
                    :value="model.id"
                    :label="model.name"
                  >{{model.name}}</el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>
        </div>
        <div class="card-footer">
          <div class="col-12 text-center">
            <base-button plain type="secondary" @click="cancel">{{
              getTrans("messages.cancel")
            }}</base-button>
            <base-button type="primary" @click="addLink">{{
              getTrans("messages.add")
            }}</base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="card">
        <div class="card-header">
          <div class="h2">{{getTrans('messages.supported_sites')}}</div>
        </div>
        <div class="card-body">
          <p class="card-text">{{getTrans('messages.supported_sites_text')}}</p>
          <div class="card-text">
              <a class="list-group-item list-group-item-action" target="_blank" v-for="platform in getClipsites" :key="platform.id" :href="platform.site_url">{{platform.title}}
                  <span v-if="isCaptchaBlocked(platform.site_url)" :title="getTrans('messages.link_protected_warning')">
                      <i class="fa fa-exclamation-triangle text-warning"></i>
                  </span>
              </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ElSelect, ElOption, ElTooltip } from "element-plus";
import Errors from "@/mixins/Errors";
import BaseAlert from "../../components/BaseAlert.vue";
export default {
  name: 'add-link-page',
  components: {
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    [ElTooltip.name]: ElTooltip,
    BaseAlert
  },
  mixins: [Errors],
  props: {
    profileId: {
      type: [String, Number, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      url: 'https://',
      title: '',
      platformId: null,
      message: '',
      loaded: false,
      model: null,
      editing: false,
    };
  },
  watch: {
    selectedCamsite() {
      this.filtersUpdated();
    },
    currentSearch() {
      this.filtersUpdated();
    },
  },
  computed: {
    ...mapGetters('Profile', {
      link: 'getData',
      errors: 'getErrors'
    }),
    ...mapGetters('User', { getUser: 'getData', isStudio: 'isStudio' }),
    ...mapGetters('Model', { getModels: 'getList' }),
    ...mapGetters('Platform', {
      getClipsites: 'getClipsites',
      isCaptchaBlocked: 'isCaptchaBlocked',
    }),
    showModels() {
      return this.getUser.permissions['models'] ? true : false;
    },
  },
  methods: {
    selectedProfilesUpdated(profiles) {
      this.selectedProfiles = profiles;
    },
    clear() {
      this.url = 'https://';
      this.title = '';
      this.message = '';
      this.site = '';
      this.model = '';
    },
    cancel() {
      this.$router.go(-1);
    },
    async addLink() {
      let user_id = this.model ? this.model : this.getUser.id;
      if (this.editing) {
        this.$store.dispatch('Profile/save', {
            id: this.editing,
            user_id,
            url: this.url,
            title: this.title,
            description: this.message,
            type: '2',
          })
          .then(() => {
            this.$toast.success(this.getTrans('messages.link_saved'));
            this.$router.push({ name: 'links' });
          }).catch(()=>{
            this.$toast.error(this.getTrans('messages.save_failed'));
          });
      } else {
        this.$store.dispatch('Profile/create', {
          user_id,
          url: this.url,
          title: this.title,
          description: this.message,
          type: '2',
        }).then(() => {
          this.$toast.success(this.getTrans('messages.link_created'));
          this.$router.push({ name: 'links' });
        }).catch(() => {
          this.$toast.error(this.getTrans('messages.save_failed'));
        });
      }
    },
    setLink(link) {
      if (link) {
        this.editing = link.id;
        this.url = link.url;
        this.title = link.title;
        this.message = link.description;
        this.model = link.user_id;
      }
    },
  },
  mounted() {
    let promises = [];
    if (this.profileId) {
      promises.push(this.$store.dispatch('Profile/getById', this.profileId))
    }
    if (this.isStudio) {
      promises.push(this.$store.dispatch('Model/getList'));
    }
    Promise.all(promises).then(() => {
      if (this.profileId) {
        this.setLink(this.link)
      }
      this.loaded = true;
    });
  },
};
</script>
